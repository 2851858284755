<template>
  <v-row v-for="(term, key) in taxonomyData" :key="key" class="pl-1 pa-0">
    <v-col v-if="key !== 'Popularity'" class="text-left" cols="5">{{ key }}</v-col>
    <v-col v-if="key === 'Popularity'" cols="12" class="text-center">
      <Rating v-model="popularity" hover @update:model-value="popularity = value"></Rating>
    </v-col>
    <v-col v-else class="text-left" cols="7">
      <template v-if="key === 'Current Jackpot'">
        <span class="text-jackpot font-weight-bold"> {{ term }} </span>
      </template>
      <template
        v-else-if="
          typeof term === 'object' &&
          !Array.isArray(term) &&
          term !== null &&
          (key === 'Support options' || key === 'Welcome Bonus')
        "
      >
        <div v-for="option in getObjectVal(term)" :key="option.name">{{ option.name }} {{ option.val }}</div>
      </template>
      <template v-else-if="Array.isArray(term)">
        <see-more :arr="term" />
      </template>
      <smart-link v-else :to="term?.uri"> {{ term?.title ? term.title : term }}</smart-link>
    </v-col>
  </v-row>
</template>
<script setup>
import { formatTaxonomy } from "@/lib/tools";
const page = inject("page");

const props = defineProps({
  taxonomy: {
    type: Object,
    default: () => {},
  },
});
const { taxonomy } = toRefs(props);
const popularity = unref(page)?.rels?.Popularity;
const getObjectVal = value => {
  const tmp = [];
  _.mapKeys(value, function (value, key) {
    tmp.push({
      name: key !== "title" ? `${key} :` : "",
      val: value?.title || Array.isArray(value) ? value.join(", ") : value,
    });
  });
  return tmp;
};
const taxonomyData = formatTaxonomy(taxonomy.value);
</script>
